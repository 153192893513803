import "bootstrap";
import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      message: "",
      email: "",
      actionMessage:
        "Write me with your request. I will typically get back to you within 24 hours",
      errorState: false,
      submitted: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  handleFormSubmit = (e) => {
    e.preventDefault();
    var formData = this.state;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}`,
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then((result) => {
        if (result.data.sent) {
          this.setState({ error: false });
          this.setState({
            actionMessage: "Your message has been sent sucessfully",
          });
          this.setState({ submitted: true });
        } else {
          this.setState({ error: true });
          this.setState({
            actionMessage: "There was an issue submitting your message",
          });
        }
      })
      .catch((error) => this.setState({ actionMessage: error.message }));
  };

  render() {
    return (
      <Container fluid>
        <Row className="article">
          <Col sm={6} className="offset-sm-3 justify-content-center custCol p-4">
            <div className="contact-form">
              <div className="contact-form__header">
                <h2>Contact Form</h2>
                <p></p>
                <div>
                  {" "}
                  <p>{this.state.actionMessage} </p>
                </div>
              </div>
              <div className="contact-form__container">
                <div>
                  {this.state.submitted === false && (
                    <form onSubmit={this.handleFormSubmit}>
                      <label>Name {this.state.name}</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your First Name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />

                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder=" Your Email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                      <label>Message</label>
                      <textarea
                        name="message"
                        placeholder="Write something....."
                        value={this.state.message}
                        onChange={this.handleInputChange}
                      ></textarea>
                      <input type="submit" value="Submit" />
                      <div></div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ContactForm;
