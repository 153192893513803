
import { SocialIcon } from 'react-social-icons';
import {Col} from 'react-bootstrap';

const brightPink = "#f25caf";
function Footer() {
    return (
        <div className="yellowShadow boxStyle row footer">
            <Col>
                <SocialIcon network="github" bgColor={brightPink} url="https://github.com/cscheerio" />            
                <SocialIcon network="twitter" bgColor={brightPink} url="https://twitter.com/chelsearcheerio" />
                <SocialIcon network="linkedin" bgColor={brightPink} url="https://www.linkedin.com/in/chelsea-hardy/" />
            </Col>
        </div>
    );
}

export default Footer;
