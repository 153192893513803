
import profile from '../../img/profileTransparentPink.png';
import {Container, Row, Col} from 'react-bootstrap';

function Header() {
    return (
        <header className="boxStyle">
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <img className="circular-square  w-50" alt="chelsea hardy, white woman" src={profile} />
                    </Col>
                    <Col md={7}>
                        <Row>
                            <h1>Chelsea Hardy</h1>
                        </Row>
                        <Row>
                            <h2> Be a unicorn in a field of horses. - Unknown</h2>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </header >
    );
}

export default Header;
