import SubHeader from "../basic/SubHeader";

function About() {
  return (
    <>
      <SubHeader text="About" />
      <p>ME</p>
    </>
  );
}

export default About;
