import Box from './basic/Box';
function Flyout() {
    return (
        <div className='article row justify-content-center'>
            <Box
                header='About'
                text='My name is Chelsea and I have been working in IT for over a decade. 
                I have a proven history of providing simple sites to communities
                 and small businesses.'/>

            <Box 
                header='Welcome to my website'
                text='I build cost effective websites community organizations and 
                businesses to leverage technology when serving their clients.' />
        </div>
    );
}

export default Flyout;