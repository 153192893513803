import Header from './components/basic/Header';
import Footer from './components/Footer';
import { Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Samples from './components/Samples';
import Contact from './components/pages/Contact';
import Nav from './components/Nav'

function App() {
  return (
    <div className="App">
      <Header />        
      <Nav />    
    <main>
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/sample" component={Samples} />            
            <Route path="/contact" component={Contact} />
        </Switch>
    </main>
      <Footer />
    </div>
  );
}

export default App;
