import janesWalk from "../img/samples/janeswalk.jpg";
import laloop from "../img/samples/laloop.jpg";
import sfoa from "../img/samples/sfoa.png";
import willowplains from "../img/samples/willowplains.png";
import walks from "../img/samples/walks.PNG";
import srra from "../img/samples/srra.png";
import sin7 from "../img/samples/sin7.png";
import { CardColumns, Row } from "react-bootstrap";
import SampleBox from "./SampleBox";

function Samples() {
  return (
    <div className="sample">
      <Row className="p-4 article">
        <CardColumns>
          <SampleBox
            link="https://sinister7.chelseahardy.ca"
            img={sin7}
            heading="Sinister 7 Stats"
          />

          <SampleBox
            link="https://walks.chelseahardy.ca"
            img={walks}
            heading="Jane's Walk React"
          />
          <SampleBox
            link="https://janeswalksaskatoon.ca"
            img={janesWalk}
            heading="Jane's Walk Saskatoon"
          />
        </CardColumns>
        <CardColumns>
          <SampleBox
            link="https://laloopsalon.com"
            img={laloop}
            heading="La Loop Salon"
          />

          <SampleBox
            link="https://saskatoonroadrunners.ca"
            img={srra}
            heading="SRRA"
          />
          <SampleBox link="https://sfoa.ca" img={sfoa} heading="SFOA" />

          <SampleBox
            link="https://willowplainsyoga.com"
            img={willowplains}
            heading="Willow Plains"
          />
        </CardColumns>
      </Row>
    </div>
  );
}

export default Samples;
