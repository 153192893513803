import SubHeader from "../basic/SubHeader";
import Flyout from "../Flyout";
import Samples from "../Samples";
import {Container} from 'react-bootstrap';

function Home() {
  return (
    <>
      <Container fluid className="overflow-hidden">
        <Flyout />
      </Container>

      <SubHeader text="Samples" />
      <Container fluid className="overflow-hidden">
        <Samples />
      </Container>
    </>
  );
}

export default Home;
