import React from 'react';
import {Row, Col} from 'react-bootstrap';

function SubHeader(props) {
  return (
    <div className="yellowShadow subHeading">
      <Row>
        <Col className="p-2">
          <h2 className="text-center"> {props.text}</h2>
        </Col>
      </Row>
    </div>
  );
}

export default SubHeader;
