import {  NavLink } from 'react-router-dom';
import {Nav} from 'react-bootstrap';


function Navigation() {
    return (
        <nav>
            <div className='d-flex flex-row-reverse yellowShadow p-2'>
                <Nav className="navCust">
                        <Nav.Item className="p-2"><NavLink to="/contact">Contact</NavLink> </Nav.Item>
                        <Nav.Item className="p-2"><NavLink to="/">Home</NavLink></Nav.Item>
                </Nav>
            </div>
        </nav>
    );
}
export default Navigation;

