import React from "react";
import { Card, Button } from "react-bootstrap";

function SampleBox(props) {
  return (
    <Card className="custCol">
      <a href={props.link} rel="noreferrer" target="_blank">
        <Card.Img variant="top" src={props.img} />
      </a>
      <Card.Body>
        <Card.Title>
          <h3 className="text-center">{props.heading} </h3>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default SampleBox;
